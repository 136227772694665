import React, { CSSProperties, forwardRef, ReactNode, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactVirtualizedAutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { Term } from '../../libraries/db-types';
import { countFormat } from '../../libraries/utilities';
import { SearchHistory } from '../../services/local-storage-service';
import DB from '../../services/storage-service';
import Icon from '../icons/icons';
import { LocationState } from '../page-stack';
import Spacer from '../spacer/spacer';
import './dens-list-view.scss';

type Props = {
  type?: 'histories' | 'terms',
  view?: boolean;
  items: SearchHistory[] | Term[],
  // list: number[], // post_id のリスト
  // singleViewRequest: (index: number) => void
  title?: string,
  children?: ReactNode,
  onListClick?: (words: string) => void
  onDeleteClick?: (data: SearchHistory, index: number) => void
}

// const ListView: React.FC<Props> = (props) => {
const DensListView = forwardRef<unknown, Props>((props, ref) => {

  // 親で実行する関数
  useImperativeHandle(ref, () => ({
    scrollToTop: () => listRef.current?.scrollTo(0)
  }));

  const navigate = useNavigate();
  const listRef = React.createRef<FixedSizeList<any>>();

  const onListClick = (data: SearchHistory | Term, index: number) => {
    // console.log('list click', data, index);
    if (props.type === 'histories') {
      // 検索履歴
      props.onListClick && props.onListClick((data as SearchHistory).words);
    } else {
      // タグ一覧
      // view 数を1つ足す
      const updateData = (data as Term);
      updateData.term_view += 1
      DB.updateData('Terms', (data as Term).term_id, updateData);
      DB.getPostItemsByTeams((data as Term).term_name).then(ret => {
        // console.log('navigate to list', ret,(data as Term).term_name);
        ret.length && navigate('/list', { state: {
          items: ret,
          title: `# ${(data as Term).term_name}`,
          view: 'list',
         } as LocationState});
        // window.history.pushState({state: "history"}, '', "/article");
        // console.log(window.history.state);
      });
    }
  }

  const onDeleteClick = (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>, data: SearchHistory, index: number) => {
    ev.stopPropagation();
    // console.log('delete click');
    props.onDeleteClick && props.onDeleteClick(data, index);
  }

  const Row = (p: {
    data: SearchHistory | Term,
    index: number,
    style: CSSProperties,
  }) => {
    return <div className={`dens-list-item${ props.type === 'terms' ? ' has-right-icon' : ''}`} style={p.style} onClick={() => onListClick(p.data, p.index)}>
      {props.type === 'histories' 
        ? <Icon name='search' onClick={() => {}}></Icon>
        : <i className='icon-label'></i>}
      <span className='words' dangerouslySetInnerHTML={{__html: props.type === 'histories' ? (p.data as SearchHistory).words : (p.data as Term).term_name}}></span>
      <Spacer></Spacer>
      {(props.type !== 'histories' && props.view) ? <span className='count view-count'>
        <Icon name='views' onClick={() => {}}></Icon>
        {countFormat((p.data as Term).term_view)}
      </span> : null}
      <span className='count'>
        {/* {props.type !== 'histories' ? <Icon name='article' onClick={() => {}}></Icon> : null} */}
        {countFormat(props.type === 'histories' ? (p.data as SearchHistory).count : (p.data as Term).term_count)}
      </span>
      {/* {props.type === 'terms' && <span className='view-count'>{(p.data as Term).term_view}</span>} */}
      {props.type === 'histories' && <span className='del-button' onClick={ev => onDeleteClick(ev, p.data as SearchHistory, p.index)}>×</span>}
    </div>
  }

  return <>
  {props.items.length ?
  <ReactVirtualizedAutoSizer>
    {({ height, width }: Size) => {
      // console.log('DensListView rendered')
      return <FixedSizeList
        ref={listRef}
        height={height}
        width={width}
        itemCount={props.items.length}
        itemSize={48}
      >
        {p => Row({...p, data: props.items[p.index]})}
      </FixedSizeList>
    }}
  </ReactVirtualizedAutoSizer>
  :
    <div>{props.children}</div>
  }
  </>
})
export default DensListView;
