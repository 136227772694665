import { Connection, DATA_TYPE, IDataBase, ITable } from 'jsstore';

// 読み込むWorkerファイルのパス
const getWorkerPath = () => {
  if (process.env.NODE_ENV === 'development') {
    //     // return require("file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.js");
    return 'jsstore.worker.js'
  }
  else {
    // return require("file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.min.js");
    return 'jsstore.worker.min.js'
  }
};

// This will ensure that we are using only one instance. 
// Otherwise due to multiple instance multiple worker will be created.
const workerPath = `${process.env.PUBLIC_URL}/assets/js/jsstore/${getWorkerPath()}`;

// indexeddb に接続
export const idbCon = new Connection(new Worker(workerPath));

// DB名
export const dbname = 'AppData';

// DB取得
const getDatabase = () => {
  // テーブル（オブジェクトストア）の定義
  // const tblStudent: ITable = {
  //   name: 'Students',
  //   columns: {
  //     id: {
  //       primaryKey: true,
  //       autoIncrement: true
  //     },
  //     name: {
  //       notNull: true,
  //       dataType: DATA_TYPE.String
  //     },
  //     gender: {
  //       dataType: DATA_TYPE.String,
  //       default: 'male'
  //     },
  //     country: {
  //       notNull: true,
  //       dataType: DATA_TYPE.String
  //     },
  //     city: {
  //       dataType: DATA_TYPE.String,
  //       notNull: true
  //     }
  //   }
  // };

  /**
   * サイト情報テーブル
   */
  const tblGeneral: ITable = {
    name: 'General',
    columns: {
      title: {
        primaryKey: true,
        dataType: DATA_TYPE.String
      },
      link: { dataType: DATA_TYPE.String },
      description: { dataType: DATA_TYPE.String },
      pubDate: { dataType: DATA_TYPE.DateTime },
      language: { dataType: DATA_TYPE.String },
      wxr_version: { dataType: DATA_TYPE.Number },
      base_site_url: { dataType: DATA_TYPE.String },
      base_blog_url: { dataType: DATA_TYPE.String },
      generator: { dataType: DATA_TYPE.String },
      image: {}
    }
  }

  /**
   * 著者テーブル
   */
  const tblAuthor: ITable = {
    name: 'Authors',
    columns: {
      author_id: {
        primaryKey: true,
        dataType: DATA_TYPE.Number,
      },
      author_login: { dataType: DATA_TYPE.String },
      author_email: { dataType: DATA_TYPE.String },
      author_display_name: { dataType: DATA_TYPE.String },
      author_first_name: { dataType: DATA_TYPE.String },
      author_last_name: { dataType: DATA_TYPE.String },
    }
  }

  /**
   * カテゴリーテーブル
   */
  const tblCategory: ITable = {
    name: 'Categories',
    columns: {
      term_id: {
        primaryKey: true,
        dataType: DATA_TYPE.Number,
      },
      category_nicename: { dataType: DATA_TYPE.String },
      category_parent: { dataType: DATA_TYPE.String },
      cat_name: { dataType: DATA_TYPE.String },
    }
  }

  /**
   * tag テーブル
   */
  const tblTag: ITable = {
    name: 'Tags',
    columns: {
      term_id: {
        primaryKey: true,
        dataType: DATA_TYPE.Number,
      },
      tag_slug: { dataType: DATA_TYPE.String },
      tag_name: { dataType: DATA_TYPE.String },
    }
  }

  /**
   * タクソノミーテーブル
   */
  const tblTerm: ITable = {
    name: 'Terms',
    columns: {
      term_id: {
        primaryKey: true,
        dataType: DATA_TYPE.Number,
      },
      term_taxonomy: { dataType: DATA_TYPE.String },
      term_slug: { dataType: DATA_TYPE.String },
      term_parent: { dataType: DATA_TYPE.String },
      term_name: { dataType: DATA_TYPE.String },
      // additional
      term_view: { dataType: DATA_TYPE.Number },
      term_count: { dataType: DATA_TYPE.Number }
    }
  }

  /**
   * 投稿テーブル
   */
  const tblItem: ITable = {
    name: 'Items',
    columns: {
      title: { dataType: DATA_TYPE.String },
      link: { dataType: DATA_TYPE.String },
      pubDate: { dataType: DATA_TYPE.DateTime },
      creator: { dataType: DATA_TYPE.String },
      guid: { dataType: DATA_TYPE.String },
      description: { dataType: DATA_TYPE.String },
      content: { dataType: DATA_TYPE.String },
      excerpt: { dataType: DATA_TYPE.String },
      post_id: {
        primaryKey: true,
        dataType: DATA_TYPE.Number,
      },
      post_date: { dataType: DATA_TYPE.DateTime },
      post_date_gmt: { dataType: DATA_TYPE.DateTime },
      post_modified: { dataType: DATA_TYPE.DateTime },
      post_modified_gmt: { dataType: DATA_TYPE.DateTime },
      comment_status: { dataType: DATA_TYPE.String },
      ping_status: { dataType: DATA_TYPE.String },
      post_name: { dataType: DATA_TYPE.String },
      status: { dataType: DATA_TYPE.String },
      post_parent: { dataType: DATA_TYPE.Number },
      menu_order: { dataType: DATA_TYPE.Number },
      post_type: { dataType: DATA_TYPE.String },
      post_password: { dataType: DATA_TYPE.String },
      is_sticky: { dataType: DATA_TYPE.Number },
      attachment_url: { dataType: DATA_TYPE.String },
      category: { dataType: DATA_TYPE.Array, multiEntry: true },
      post_tag: { dataType: DATA_TYPE.Array, multiEntry: true },
      postmeta: { dataType: DATA_TYPE.Array, multiEntry: true },
      // additional
      post_fav: { dataType: DATA_TYPE.Boolean },
      post_view: { dataType: DATA_TYPE.Number },
    }
  }

  /**
   * メディアテーブル (attachment)
   */
   const tblMedia: ITable = {
    name: 'Media',
    columns: {
      title: { dataType: DATA_TYPE.String },
      link: { dataType: DATA_TYPE.String },
      pubDate: { dataType: DATA_TYPE.DateTime },
      creator: { dataType: DATA_TYPE.String },
      guid: { dataType: DATA_TYPE.String },
      description: { dataType: DATA_TYPE.String },
      // content: { dataType: DATA_TYPE.String },
      // excerpt: { dataType: DATA_TYPE.String },
      post_id: {
        primaryKey: true,
        dataType: DATA_TYPE.Number,
      },
      post_date: { dataType: DATA_TYPE.DateTime },
      post_date_gmt: { dataType: DATA_TYPE.DateTime },
      post_modified: { dataType: DATA_TYPE.DateTime },
      post_modified_gmt: { dataType: DATA_TYPE.DateTime },
      comment_status: { dataType: DATA_TYPE.String },
      ping_status: { dataType: DATA_TYPE.String },
      post_name: { dataType: DATA_TYPE.String },
      status: { dataType: DATA_TYPE.String },
      post_parent: { dataType: DATA_TYPE.Number },
      menu_order: { dataType: DATA_TYPE.Number },
      post_type: { dataType: DATA_TYPE.String },
      post_password: { dataType: DATA_TYPE.String },
      is_sticky: { dataType: DATA_TYPE.Number },
      attachment_url: { dataType: DATA_TYPE.String },
      // category: { dataType: DATA_TYPE.Array, multiEntry: true },
      // post_tag: { dataType: DATA_TYPE.Array, multiEntry: true },
      postmeta: { dataType: DATA_TYPE.Array, multiEntry: true },
      // additional
      post_fav: { dataType: DATA_TYPE.Boolean },
      post_view: { dataType: DATA_TYPE.Number },
    }
  }

  // DBを作成
  const dataBase: IDataBase = {
    name: dbname,
    tables: [
      tblGeneral,
      tblAuthor,
      tblCategory,
      tblTag,
      tblTerm,
      tblItem,
      tblMedia
    ]
  };
  return dataBase;
};

// 初期化用関数
export const initJsStore = () => {
  // DB作成を試行
  try {
    const dataBase = getDatabase();
    idbCon.initDb(dataBase);
  }
  catch (ex) {
    console.error(ex);
  }
};

export class BaseService {

  get connection() {
    return idbCon;
  }

}