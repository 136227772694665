import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import List from "../layouts/list/list";
import Single from "../layouts/single/single";
import { Item, Media } from "../libraries/db-types";

type Props = {
  // view: 'single' | 'list' | 'main',
  // data: SingleProps | ListProps | null,
}

export type LocationState = {
  view: 'single' | 'list' | 'photo',
  title?: string,
  index?: number,
  items: Item[] | Media[],
}

const PageStack: React.FC<Props> = (props) => {
  const location = useLocation();
  const navigate = useNavigate()
  const navgationType = useNavigationType();
  const [stack, setStack] = useState<any[]>([]);

  const onBackButtonClick = useCallback(() => {
    // ここでアニメーションする
    const stacks = document.querySelectorAll(".App > .page");
    const targetPage = stacks[stacks.length - 1];
    // console.log('onBackButtonClick', stacks, targetPage);
    targetPage.classList.add('slide-out');
    setTimeout(() => {
      navigate(-1);
    }, 200);
  }, [navigate]);
  
  useEffect(() => {
    
    const setStackItem = () => {
      // view によって入れるコンポーネントを変える
      switch ((location.state as LocationState).view) {
        case 'single':
          setStack(pre => [...pre, <Single
            data={(location.state as LocationState)}
            onBackButtonClick={onBackButtonClick}></Single>]);
          break;
        case 'photo':
          setStack(pre => [...pre, <Single photo
            index={(location.state as LocationState).index}
            data={(location.state as LocationState)}
            onBackButtonClick={onBackButtonClick}></Single>]);
          break;
        case 'list':
          setStack(pre => [...pre, <List
            data={(location.state as LocationState)}
            onBackButtonClick={onBackButtonClick}></List>]);
          break;
        default:
          break;
      }
    }
    // console.log(location, navgationType, stack);
    if (location.pathname === '/') {
      // Topなら常に空
      setStack(pre => []);
    } else {

      if (navgationType === 'PUSH') {
        // ページ追加の場合
        // state があればスタックに追加する
        if (location.state) {
          setStackItem();
        }
      } else if (navgationType === 'POP') {
        // 戻るボタンの場合
        // stack がなくなるまで戻れる
        setStack(pre => pre.length > 0 ? pre.slice(0, -1) : pre);
        // リロードでも1回走るので、itemがあったら復元しないといけない?
        // if (location.pathname !== '/' && location.state) {
        //   setStackItem();
        // }
      }
      // Replace なら何もしない
    }
  }, [location, navgationType, onBackButtonClick])

  return <>
    {stack.map((num, index) => <div className="page" key={index}>{num}</div>)}
  </>

}
export default PageStack;