import React, { CSSProperties, forwardRef, ReactNode, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactVirtualizedAutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeGrid } from 'react-window';
import { Media } from '../../libraries/db-types';
import { myEnv } from '../../libraries/_env';
import Icon from '../icons/icons';
import { LocationState } from '../page-stack';
import './photo-list-view.scss';

type Props = {
  items: Media[],
  // list: number[], // post_id のリスト
  // singleViewRequest: (index: number) => void
  children?: ReactNode,
}


// const ListView: React.FC<Props> = (props) => {
const PhotoListView = forwardRef<unknown, Props>((props, ref) => {

  // 親で実行する関数
  useImperativeHandle(ref, () => ({
    // scrollToTop: () => listRef.current?.scrollTo(0)
  }));

  const navigate = useNavigate();
  const listRef = React.createRef<FixedSizeGrid<any>>();
  // const [items, setItems] = useState<any[]>([])

  const colmuns = myEnv.photoListColumns;
  const rows = props.items.length / colmuns + (props.items.length % colmuns !== 0 ? 1 : 0);

  const onCardClick = (index: number) => {
    // DB.getItem(props.items[index].post_id).then(data => {
      // console.log(props.items[index])
      navigate('/article', { state: {
        title: props.items[index].title,
        items: props.items,
        index: index,
        view: 'photo'
      } as LocationState});
    // })
  }

  // useEffect(() => {
  //   // console.log(props.items.length)
  // }, [props.items])

  const Row = (p: {
    data: Media,
    columnIndex: number,
    rowIndex: number,
    index: number,
    style: CSSProperties,
  }) => {
    return p.index < props.items.length ? <div className='media-cell' style={p.style} onClick={() => onCardClick(p.index)}>
      {/* <img src={`https://bbr.urchinic.com/${p.data.attachment_url}`} alt="" /> */}
      {/* <img src={`${p.data.attachment_url}`} alt={`${p.index}`} /> */}
      {/* <img src={`https://placehold.jp/${p.rowIndex + 1}0x${p.index + 1}0.png`} alt={`${p.index}`} /> */}
      <div className='media-item'
        style={{
          // backgroundImage: `url("https://placehold.jp/${p.rowIndex + 1}0x${p.index + 1}0.png")`
          backgroundImage: `url("${p.data.attachment_url}")`
          // backgroundImage: `url("https://bbr.urchinic.com/${p.data.attachment_url}")`
        }}
      >
      {p.data.post_fav && <i>
        <Icon name='favorite' onClick={() => {}} active={p.data.post_fav}></Icon>
      </i>}
      </div>
    </div> : <div></div>
  }

  return <>
  { props.items.length > 0?
  <ReactVirtualizedAutoSizer>
    {({ height, width }: Size) => {
      // console.log('FixedSizeList rendered')
      return <FixedSizeGrid
        ref={listRef}
        height={height}
        width={width}
        columnCount={colmuns}
        rowCount={rows}
        columnWidth={width / colmuns}
        rowHeight={width / colmuns}
      >
        {(p) => {
          const index = (p.rowIndex * colmuns) + p.columnIndex;
          return Row({ ...p,
          index: index,
          data: props.items[index]})
        }}
      </FixedSizeGrid>
    }}
  </ReactVirtualizedAutoSizer>
  :
    <div>{props.children}</div>
  }
  </>
})
export default PhotoListView;
