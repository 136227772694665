import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import DensListView from '../../components/dens-list-view/dens-list-view';
import NoDataFound from '../../components/no-data-found/no-data-found';
import { Term } from '../../libraries/db-types';
import DB from '../../services/storage-service';
import './terms.scss';

type Props = {
  active: boolean;
  updateSwitch: boolean;
}

// const Terms: React.FC<Props> = (props) => {
const Terms = forwardRef<unknown, Props>((props, ref) => {

  // アイテムリスト
  const [items, setItems] = useState<Term[]>([]);
  const [filteredItems, setFilterdItems] = useState<Term[]>([]);
  // const [list, setList] = useState<number[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [sortType, setSortType] = useState<string>('');

  // 親で実行する関数
  useImperativeHandle(ref, () => ({
    setFilterWords: (tagFilter: string) => {
      // console.log('setFilterWords', tagFilter);
      setFilter(_ => tagFilter);
    },
    setSortType: (sort: string) => {
      // console.log('setSortType', sort);
      setSortType(_ => sort);
    },
    }));

  useEffect(() => {
    // post_type=post, 件数は絞らず全件取得する
    DB.getExistTerms().then(ret => {
      setItems(ret);
      setFilterdItems(ret);
      // console.log('term data loaded', ret)
    });
  }, [props.updateSwitch]);

  useEffect(() => {
    const filtered = items.filter(term => term.term_name.match(new RegExp(filter)));
    // console.log('filtered', filter, filtered);

    switch(sortType) {
      case "term_id":
        setFilterdItems(filtered.sort((a, b) => a.term_id - b.term_id));
        break;
      case "term_name":
        setFilterdItems(filtered.sort((a, b) => a.term_name < b.term_name ? -1 : 1));
        break;
      case "count":
        setFilterdItems(filtered.sort((a, b) => b.term_count - a.term_count));
        break;
      case "view":
        setFilterdItems(filtered.sort((a, b) => b.term_view - a.term_view));
        break;
      default:
        setFilterdItems(filtered.sort((a, b) => b.term_count - a.term_count));
        break;
    }
  }, [items, filter, sortType]);


  return <section id="terms" className={props.active ? 'active' : 'hidden'}>
    <div className="index"></div>
    <div className='container-fluid'>
      <DensListView items={filteredItems} type={`terms`} view={sortType === 'view'}><NoDataFound></NoDataFound></DensListView>
    </div>
  </section>;
})
export default Terms;