import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import { getString, parseAttachment, parseAuthor, parseCategory, parseGeneralData, parseItem, parseTag, parseTerm } from "../../libraries/convert";
import { Author, Category, DataType, Item, Media, Tag, tblName, Term } from "../../libraries/db-types";
import { topTagSelector } from "../../libraries/utilities";
import { myEnv } from "../../libraries/_env";
import LS, { Settings } from "../../services/local-storage-service";
import DB from "../../services/storage-service";
import './settings-dialog.scss';

type Props = {
  show: boolean
  hideDialog: () => void
}

const SettingsDialog: React.FC<Props> = (props) => {

  // let doc: Document = new Document();
  const navigate = useNavigate();
  const [appEl, setAppEl] = useState<HTMLElement | HTMLElement[] | HTMLCollection | NodeList | undefined>(undefined);
  const [dataLoadOpen, setDataLoadOpen] = useState(false);
  const [siteInfo, setSiteInfo] = useState({
    General: 0,
    Authors: 0,
    Items: 0,
    Media: 0,
    Categories: 0,
    Tags: 0
  });
  const [settings, setSettings] = useState(LS.getSettings());

  // ダイアログ終了時にリロードするかどうか
  const [shouldReload, setShouldReload] = useState(false);

  // DB 作成ダイアログ用の変数
  const [phase, setPhase] = useState(0);
  const [max, setMax] = useState(0);
  const [count, setCount] = useState(0);

  const fileRef = useRef(null);

  const toggleLoadDataDialog = (isOpen: boolean) => {
    setDataLoadOpen(isOpen);
  }

  const updateSettings = () => {
    setSettings(LS.getSettings());
  }

  const updateTermCounts = async () => {
    const terms = await DB.getTerms();

    // フェイズ更新
    setPhase(2);
    // 件数更新
    setCount(0);
    setMax(terms.length);

    return await Promise.all(terms.map(async (term) => {
      return DB.getPostItemsByTeams(term.term_name).then(ret => {
        const counted = term;
        counted.term_count = ret.length;
        setCount(pre => pre + 1);
        return DB.updateData('Terms', term.term_id, counted);
      })
    })).then(ret => {
      return ret.length
    }).catch(err => {
      console.log(err);
      return 0;
    })
  }

  /**
   * 20220609 更新できるように変更
   * @param doc Document
   */
  const createDB = async (doc: Document): Promise<boolean> => {
    const dom = doc.querySelector(myEnv.topTagName);

    // channel が無かったらファイルが違うので終わり
    if (dom == null) { return false; }

    // 処理状況と処理数
    setPhase(0);
    setMax(dom.children.length);
    setCount(0);

    // 各データの配列を作る
    async function createArray(data: NodeListOf<Element>, parser: (value: Element) => Promise<DataType | null>) {
      const res = [];
      for (const value of Array.from(data)) {
        const ret = await parser(value);
        if (ret != null) {
          res.push(ret);
        }
        setCount(pre => pre + 1);
      }
      return res;
    }
    const media: Media[] = [];
    const authers: Author[] = await createArray(doc.querySelectorAll(topTagSelector('author')), parseAuthor) as Author[];
    const categories: Category[] = await createArray(doc.querySelectorAll(topTagSelector('category')), parseCategory) as Category[];
    const tags: Tag[] = await createArray(doc.querySelectorAll(topTagSelector('tag')), parseTag) as Tag[];
    const terms: Term[] = await createArray(doc.querySelectorAll(topTagSelector('term')), parseTerm) as Term[];
    const items: Item[] = await createArray(doc.querySelectorAll(topTagSelector('item')), async (value: Element) => {
      // attachmentを除外する
      if (getString(value, 'post_type') === 'attachment') {
        const attachment = await parseAttachment(value);
        if (attachment) {
          media.push(attachment);
        }
        return null;
      } else {
        return parseItem(value);
      }
    }) as Item[];


    // 処理数をDB登録数に更新
    setPhase(1);
    setMax(10 + authers.length + categories.length + tags.length + terms.length + items.length + media.length);
    setCount(0);

    // DB に登録
    return Promise.all([
      // 基本情報
      DB.getGeneral().then(general => {
        if(general.length) {
          // サイト情報は常に最新のもので上書きする
          DB.updateGeneralData(parseGeneralData(doc)).then(ret => {
            setCount(pre => pre + 10);
            return ret;
          })
        } else {
          // データが無ければ追加
          DB.addData(tblName.General, parseGeneralData(doc)).then(ret => {
            setCount(pre => pre + 10);
            return ret;
          })
        }
      }),
      // 著者
      DB.addDataArray(tblName.Authors, authers).then(ret => {
        typeof ret === 'number' && setCount(pre => pre + ret);
        return ret;
      }),
      // カテゴリ
      DB.addDataArray(tblName.Categories, categories).then(ret => {
        typeof ret === 'number' && setCount(pre => pre + ret);
        return ret;
      }),
      // タグ
      DB.addDataArray(tblName.Tags, tags).then(ret => {
        typeof ret === 'number' && setCount(pre => pre + ret);
        return ret;
      }),
      // タクソノミ
      DB.addDataArray(tblName.Terms, terms).then(ret => {
        typeof ret === 'number' && setCount(pre => pre + ret);
        return ret;
      }),
      // 投稿
      DB.addDataArray(tblName.Items, items).then(ret => {
        typeof ret === 'number' && setCount(pre => pre + ret);
        return ret;
      }),
      // メディア
      DB.addDataArray(tblName.Media, media).then(ret => {
        typeof ret === 'number' && setCount(pre => pre + ret);
        return ret;
      })
    ]).then(async _ => {
      // setPhase(2);
      // タクソノミの件数を更新する
      return updateTermCounts().then(ret => {
        if (ret) {
          setPhase(3);
          // console.log('DB 作成完了', ret);
          // 完了を押せるようにする
          // setCount(max);
          return true;
        } else {
          setPhase(4);
          // 完了を押せるようにする
          // setCount(max);
          return false;
        }
      });
    }).catch(err => {
      setPhase(4);
      console.log(err);
      // 完了を押せるようにする
      // setCount(max);
      return false;
    });
  }


  /**
   * ファイルを読み込む
   */
  const loadFile = () => {
    // ファイルを読み込みDBに登録する
    const file = fileRef.current ? (fileRef.current as HTMLInputElement).files : null;

    if (file?.length) {


      const reader = new FileReader();

      reader.addEventListener('load', async () => {

        const parser = new DOMParser();

        const doc = parser.parseFromString((reader.result as string)
          // wp接頭辞を削除する
          .replace(/<wp:(.*?)>/g, '<$1>').replace(/<\/wp:(.*?)>/g, '</$1>')
          // dc:と:encodedを削除する
          .replace(/<dc:(.*?)>/g, '<$1>').replace(/<\/dc:(.*?)>/g, '</$1>')
          .replace(/<(.*?):encoded>/g, '<$1>').replace(/<\/(.*?):encoded>/g, '</$1>')
          , "text/xml");
        // console.log(doc, doc.querySelector('channel > image'), doc.querySelector("channel > tag"));
        // parseXml(doc);
        // data2db(doc);

        // channel が無かったらファイルが違うので終わり
        if (doc.querySelector(myEnv.topTagName) == null) {
          alert(myEnv.strings.settings.formatNoMatch)
          return;
        }

        // サイトが違う場合は警告
        const siteData = await DB.getGeneral();
        const siteLink = doc.querySelector(`${myEnv.topTagName} link`)?.innerHTML;
        if (siteData[0]) {
         if(siteLink !== siteData[0].link) {
           // URL が違う
          if (window.confirm(myEnv.strings.settings.siteNoMatch)){
            // 新しいサイトで置換擦るのでテーブルを落とす
            await DB.deleteAllTables();
          } else {
            // 置換しないなら中止
            alert(myEnv.strings.settings.abort);
            return;
          }
        } else {
          // URL が同じ
          if (window.confirm(myEnv.strings.settings.siteDataExist)){
            // 更新 OK なら何もせず更新
          } else {
            // 更新しないなら中止
            alert(myEnv.strings.settings.abort);
            return;
          }
        }
      }

        // ダイアログを開く
        toggleLoadDataDialog(true);
        // data2db(doc);

        // if (siteInfo.General > 0) {
        //   // データがあったら最初にテーブルを落とす
        //   tableClear();
        // }
        createDB(doc).then(ret => {
          // 設定を更新
          setSettings(pre => {
            return {
              siteDir: pre.siteDir,
              imgPath: pre.imgPath,
              lastFName: file[0].name,
              lastLoadDate: format(new Date(), 'yyyy/MM/dd hh:mm:ss', { locale: ja })
            } as Settings
          });

          // ファイル読み込みが完了していたら設定終了時にリロードする
          setShouldReload(ret);
        });
      });

      // ファイル読込
      reader.readAsText(file[0]);
    } else {
      alert(myEnv.strings.settings.noFileSelected);
      // toggleLoadDataDialog(true);
    }

  }

  /**
   * データ削除ボタン
   */
  const tableClear = () => {
    if (window.confirm(myEnv.strings.settings.deleteData)) {
      LS.clean();
      return DB.deleteAllTables().then(_ => {
        updateSettings();
        return siteInfoUpdate();
      });
    }
  }


  /**
   * DB 作成完了ボタン
   */
  const dataLoadComplete = () => {
    siteInfoUpdate().then(() => {
      toggleLoadDataDialog(false);
    });
  }

  const siteInfoUpdate = () => {
    // サイト情報を取得
    const counts = {
      General: 0,
      Authors: 0,
      Items: 0,
      Media: 0,
      Categories: 0,
      Tags: 0
    };

    return Promise.all([
      DB.getDataCount('General').then(ret => counts.General = ret),
      DB.getDataCount('Authors').then(ret => counts.Authors = ret),
      DB.getDataCount('Items').then(ret => counts.Items = ret),
      DB.getDataCount('Media').then(ret => counts.Media = ret),
      DB.getDataCount('Categories').then(ret => counts.Categories = ret),
      DB.getDataCount('Tags').then(ret => counts.Tags = ret),
    ]).then(() => {
      setSiteInfo(counts);
    });
  };

  const dropDatabase = () => {
    LS.clean();
    DB.dropDB().then(_ => {
      updateSettings();
      setShouldReload(true);
    }).catch(err => {
      console.log(err);
    });
  }

  const settingFinish = () => {
    if (shouldReload) {
      navigate(0);
    } else {
      props.hideDialog();
    }
  }

  const update = () => {
    if (window.confirm(myEnv.strings.settings.version.doUpdate)) {
      window.navigator.serviceWorker.getRegistrations().then(registrations => {
        for (const registration of registrations) {
          // console.log('unregister:', registration);
          registration.unregister();
        }
        // window.alert(myEnv.strings.settings.version.updated);
        window.location.reload();
      }).catch(err => {
        window.alert(`${myEnv.strings.settings.version.updateFailed}\n${err}`);
        window.location.reload();
      })
    }
  }

  const imgPathUpdate = (path: string) => {
    setSettings(pre => {
      return {
        imgPath: path,
        siteDir: pre.siteDir,
        lastFName: pre.lastFName,
        lastLoadDate: pre.lastLoadDate
      } as Settings
    });
  }
  const siteDirUpdate = (path: string) => {
    setSettings(pre => {
      return {
        imgPath: pre.imgPath,
        siteDir: path,
        lastFName: pre.lastFName,
        lastLoadDate: pre.lastLoadDate
      } as Settings
    });
  }
  const settingDefault = () => {
    setSettings(pre => {
      return {
        imgPath: myEnv.imgUploadDir.to,
        siteDir: myEnv.siteDir,
        lastFName: pre.lastFName,
        lastLoadDate: pre.lastLoadDate
      } as Settings
    })
  }

  useEffect(() => {
    setAppEl(document.getElementsByClassName('.App'));
    siteInfoUpdate();
    // console.log(settings);
  }, []);

  useEffect(() => {
    // console.log('settings change');
    LS.setSettings(settings);
  }, [settings])


  return <ReactModal
    isOpen={props.show}
    ariaHideApp={false}
    appElement={appEl}
    portalClassName=""
    className="settings"
    style={{}}
    contentElement={(props, children) => <dialog {...props} open>{children}</dialog>}>
    <article>
      <header><h4>Settings</h4></header>
      <section>
        <h5>{myEnv.strings.settings.menu.version}</h5>
        <table role="grid">
          <tbody>
            <tr>
              <th>{myEnv.strings.settings.version.version}</th>
              <td>{myEnv.version}</td>
            </tr>
            <tr>
              <th></th>
              <td><button onClick={update}>{myEnv.strings.settings.buttons.update}</button></td>
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h5>{myEnv.strings.settings.menu.siteInfo}</h5>
        <table role="grid">
          <tbody>
            <tr>
              <th colSpan={2}>{myEnv.strings.settings.siteInfo.fname}</th></tr><tr>
              <td colSpan={2}>{settings.lastFName}</td>
            </tr>
            <tr>
              <th>{myEnv.strings.settings.siteInfo.date}</th>
              <td>{settings.lastLoadDate}</td>
            </tr>
            <tr>
              <th>{myEnv.strings.settings.siteInfo.General}</th>
              <td>{siteInfo.General} {myEnv.strings.settings.siteInfo.Count}</td>
            </tr>
            <tr>
              <th>{myEnv.strings.settings.siteInfo.Authors}</th>
              <td>{siteInfo.Authors} {myEnv.strings.settings.siteInfo.Count}</td>
            </tr>
            <tr>
              <th>{myEnv.strings.settings.siteInfo.Items}</th>
              <td>{siteInfo.Items} {myEnv.strings.settings.siteInfo.Count}</td>
            </tr>
            <tr>
              <th>{myEnv.strings.settings.siteInfo.Media}</th>
              <td>{siteInfo.Media} {myEnv.strings.settings.siteInfo.Count}</td>
            </tr>
            <tr>
              <th>{myEnv.strings.settings.siteInfo.Categories}</th>
              <td>{siteInfo.Categories} {myEnv.strings.settings.siteInfo.Count}</td>
            </tr>
            <tr>
              <th>{myEnv.strings.settings.siteInfo.Tags}</th>
              <td>{siteInfo.Tags} {myEnv.strings.settings.siteInfo.Count}</td>
            </tr>
          </tbody>
        </table>
      </section>
      <hr />
      <section>
        <h5>{myEnv.strings.settings.menu.imgPath}</h5>
        <label htmlFor="siteDir">{myEnv.strings.settings.path.siteDir}</label>
        <input type="url" name="siteDir" id="site-dir" value={settings.siteDir} onChange={ev => siteDirUpdate(ev.target.value)} />
        <label htmlFor="siteDir">{myEnv.strings.settings.path.img}</label>
        <input type="url" name="imgPath" id="img-path" value={settings.imgPath} onChange={ev => imgPathUpdate(ev.target.value)} />
        {settings.imgPath !== myEnv.imgUploadDir.to ?
          <button onClick={settingDefault}>{myEnv.strings.settings.buttons.setDefault}</button>
          : null}
      </section>
      <hr />
      <section>
        <h5>{myEnv.strings.settings.menu.create}</h5>
        {/* <p>ファイルを選択して読込ボタンを押してください。</p> */}
        <input type="file" name="file" id="file" accept="text/xml,application/xml" ref={fileRef} />
        <button onClick={loadFile}>{myEnv.strings.settings.buttons.readFile}</button>
      </section>
      <hr />
      <section>
        <h5>{myEnv.strings.settings.menu.deleteData}</h5>
        {/* <p></p> */}
        <table role="grid">
          <tbody>
            <tr>
              <th></th>
              <td>
                <button disabled={siteInfo.General === 0} onClick={tableClear}>{myEnv.strings.settings.buttons.delete}</button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <hr />
      <section>
        <h5>{myEnv.strings.settings.menu.dropDB}</h5>
        {/* <p>DB を完全に削除します。</p> */}
        <table role="grid">
          <tbody>
            <tr>
              <th></th>
              <td>
                <button onClick={dropDatabase}>{myEnv.strings.settings.buttons.delete}</button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <footer>
        <button onClick={settingFinish}>{myEnv.strings.settings.buttons.end}</button>
      </footer>
    </article>

    <dialog open={dataLoadOpen} className="data-loading">
      <article>
        <header><h4>{(phase === 1 || phase === 2 || phase === 3 || phase === 4 || phase === 0) && myEnv.strings.settings.phase[phase]}</h4></header>
        <main>
          <progress max={max} value={count}></progress>
          {/* <div className="counter">{count} / {max}</div> */}
          <div className="counter">{phase >= 3 ? 100 : Math.floor(count / max * 100)}%</div>
        </main>
        <footer>
          <button disabled={phase < 3} onClick={dataLoadComplete}>{myEnv.strings.settings.buttons.end}</button>
        </footer>
      </article>
    </dialog>
  </ReactModal>
}
export default SettingsDialog;