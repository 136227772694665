import { ReactNode } from 'react';
import './no-data-found.scss';

type Props = {
  children?: ReactNode
}

const NoDataFound: React.FC<Props> = (props) => {
  return <div className='no-data-found'>
     <h1>😥</h1>
     <hgroup>
      <h2>Oops!</h2>
      <h3>No Data Found.</h3>
     </hgroup>
     {props.children && <p>{props.children}</p>}
  </div>
}
export default NoDataFound;