import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import DensListView from '../../components/dens-list-view/dens-list-view';
import ListView from '../../components/list-view/list-view';
import NoDataFound from '../../components/no-data-found/no-data-found';
import Toast from '../../components/toast/toast';
import { Item } from '../../libraries/db-types';
import LS, { SearchHistory } from '../../services/local-storage-service';
import DB from '../../services/storage-service';
import './search.scss';

type Props = {
  active: boolean;
  setSearchWords: (words: string) => void;
}

// const Search: React.FC<Props> = (props) => {
const Search = forwardRef<unknown, Props>((props, ref) => {

  const [words, setWords] = useState('');
  const listRef = useRef();

  // 親で実行する関数
  useImperativeHandle(ref, () => ({
    onSearchRequest: (str: string) => {
      // console.log('setWords')
      setWords(str);
    }
  }));

  // アイテムリスト
  const [items, setItems] = useState<Item[]>([]);
  // 検索履歴
  const [searchLog, setSearchLog] = useState<SearchHistory[]>([]);
  // ロード判定
  const [loading, setLoading] = useState(false);
  const [noResult, setNoResult] = useState('');

  // 履歴を削除
  const onDeleteClick = (data: SearchHistory, index: number) => {
    // console.log(data, index);
    // const filtered = searchLog.filter(log => log !== data);
    // setSearchLog(filtered);
    // LS.replaceHistory(filtered);
    setSearchLog(() => LS.removeHistory(data));
  }

  // const resetSearchWords = useCallback(() => {
  //   props.setSearchWords('');
  // }, [props.setSearchWords])

  useEffect(() => {
    // post_type=page, 件数は絞らず全件取得する
    // console.log('search effect', words);
    if (words !== '') {
      // ローディングを表示
      setLoading(true);
      DB.searchItems(words).then(ret => {
        // console.log('search result loaded', ret);
        // リストを更新
        setItems(ret);

        // ローディングを非表示
        setLoading(false);

        // 検索結果が1件でもあれば検索履歴に追加
        if (ret.length) {
          setSearchLog(() => LS.addHistory(words, ret.length));
        } else {
          setNoResult(words);
          // resetSearchWords();
          props.setSearchWords('')

          setTimeout(() => {
            setNoResult('');
          }, 2000)
        }

        // 最上部へ移動
        listRef.current && (listRef.current as any).scrollToTop();

        
      }).catch(err => {
        // ローディングを非表示
        setLoading(false);
      });
    } else {
      // console.log('no search word');
      // リストを空にする
      setItems([]);
      // 検索履歴を取得
      setSearchLog(LS.getHistory());
      // console.log('log', SearchLog.getHistory());
    }
  }, [words/*, props.setSearchWords これを入れると画面遷移の度に実行されてしまう*/])


  return <section id="search" className={props.active ? 'active' : 'hidden'}>
    <div className="index"></div>
    <div className='container-fluid'>
      {items.length ?
        <ListView ref={listRef} items={items}>
          <NoDataFound></NoDataFound>
        </ListView>
        :
        <DensListView
         items={searchLog}
         type={'histories'}
         onListClick={props.setSearchWords}
         onDeleteClick={onDeleteClick}>
          <NoDataFound>検索すると履歴が表示されます。</NoDataFound>
        </DensListView>
      }
    </div>
    {loading && <div className='loading' aria-busy={loading}></div>}
    {/* {noResult.length !== 0 && <div className='no-result'>
          <p>{noResult} に一致する検索結果がありません。</p>
        </div>} */}
    <Toast show={noResult.length !== 0} text={`${noResult} に一致する検索結果がありません。`}></Toast>
  </section>;
})
export default Search;