import { useEffect, useState } from 'react';
import NoDataFound from '../../components/no-data-found/no-data-found';
import PhotoListView from '../../components/photo-list-view/photo-list-view';
import { Media } from '../../libraries/db-types';
import { myEnv } from '../../libraries/_env';
import DB from '../../services/storage-service';
import './gallery.scss';

type Props = {
  active: boolean;
  updateSwitch: boolean;
}

const Gallery: React.FC<Props> = (props) => {

  // アイテムリスト
  const [items, setItems] = useState<Media[]>([]);

  useEffect(() => {
    // post_type=page, 件数は絞らず全件取得する
    // console.log('gallery effect')
    DB.getMedias().then(ret => {
      setItems(ret);
      // console.log('articles data loaded', data)
    });
  }, [props.updateSwitch])


  return <section id="gallery" className={props.active ? 'active' : 'hidden'}>
    <div className="container-fluid">
    <div className="index"></div>

      {/* <ListView items={items}><NoDataFound>{myEnv.strings.noDataFound}</NoDataFound></ListView> */}
      {/* {items.map((item, index) => {
        return <p key={index}>{item.title}</p>
      })} */}
      <PhotoListView items={items}><NoDataFound>{myEnv.strings.noDataFound}</NoDataFound></PhotoListView>
    </div>
  </section>;
}
export default Gallery;