import React, { forwardRef, ReactNode, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactVirtualizedAutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { Item, tblName } from '../../libraries/db-types';
import DB from '../../services/storage-service';
import Card from '../card/card';
import { LocationState } from '../page-stack';
import './list-view.scss';

type Props = {
  items: Item[],
  // list: number[], // post_id のリスト
  // singleViewRequest: (index: number) => void
  children?: ReactNode,
}


// const ListView: React.FC<Props> = (props) => {
const ListView = forwardRef<unknown, Props>((props, ref) => {

  // 親で実行する関数
  useImperativeHandle(ref, () => ({
    scrollToTop: () => listRef.current?.scrollTo(0)
  }));

  const navigate = useNavigate();
  const listRef = React.createRef<FixedSizeList<any>>();
  // const [items, setItems] = useState<any[]>([])

  const onCardClick = (index: number) => {
    // setSingleViewIndex(index);
    // console.log('card click', index);
    // props.singleViewRequest(index);
    // indexより前のデータをlistから落として渡す
    // navigate('/article', { state: {list: props.list, index: index }});
    // 最新データを取得してから渡す
    DB.getItem(props.items[index].post_id).then(data => {
      // 遷移時だけは SwipeableViews の onChangeIndex が起きないので
      // ここで view 数を足してデータを更新する
      data.post_view += 1;
      props.items[index].post_view += 1;
      DB.updateData(tblName.Items, data.post_id, data).then(_ => {
        // 遷移

        navigate('/article', { state: {
          title: data.title,
          items: props.items.slice(index),
          view: 'single'
        } as LocationState});
      });
    })
  }

  // useEffect(() => {
  //   // console.log(props.items.length)
  // }, [props.items])

  return <>
  { props.items.length > 0?
  <ReactVirtualizedAutoSizer>
    {({ height, width }: Size) => {
      // console.log('FixedSizeList rendered')
      return <FixedSizeList
        ref={listRef}
        height={height}
        width={width}
        itemCount={props.items.length}
        itemSize={272} // article.item header.excerpt/footer height + paddings + margins
      >
        {(p) => Card({ ...p, item:props.items[p.index], onClickHandler: onCardClick })}
      </FixedSizeList>
    }}
  </ReactVirtualizedAutoSizer>
  :
    <div>{props.children}</div>
  }
  </>
})
export default ListView;
