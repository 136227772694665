import PageStack from "../components/page-stack";
import { General } from "../libraries/db-types";
import Content from "./content";


type Props = {
  appData: General
  onUpdateRequest: () => void;
}

const Main: React.FC<Props> = (props) => {

  // const [appEl, setAppEl] = useState<HTMLElement | HTMLElement[] | HTMLCollection | NodeList | undefined>(undefined);
  // const [activeMenu, setActiveMenu] = useState<IconName>('home');
  // const [showSettings, setShowSettings] = useState<boolean>(false);
  // 各ページにデータ更新の必要性を伝えるための変数。値に意味はなく変更検知で使う。
  // const [updateSwitch, setUpdateSwitch] = useState(false);

  // const onActiveChange = (name: IconName) => {
  //   setActiveMenu(name);
  // }

  // 設定終了時のイベント
  // const onUpdateRequest = () => {
  //   // Homeに戻る
  //   setActiveMenu('home');

  //   // データ更新をリクエスト
  //   setUpdateSwitch(pre => !pre);

  //   // サイト情報をアップデート
  //   props.onUpdateRequest();
  // }

  // 要らなくなるかも
  // useEffect(() => {
  //   setAppEl(document.getElementsByClassName('.App'));
  // }, [appEl]);


  return (
    <>
      {/* <Routes>
        <Route path="/" element={<> */}
      {/* <TopAppBar
        title={props.appData.title}
        settingIcon
        onSettingEnd={onUpdateRequest}></TopAppBar> */}
          {/* <Content appEl={appEl} active={activeMenu} appData={props.appData} updateSwitch={updateSwitch}></Content> */}
          <Content
            appData={props.appData}
            onUpdateRequest={props.onUpdateRequest}></Content>
          {/* <NavigationBar active={activeMenu} onActiveChange={onActiveChange}></NavigationBar> */}
            {/* <Outlet></Outlet> */}
        {/* </>}> */}
        {/* <Route path="/article" element={
          <Single></Single>}></Route>
        <Route path="/list" element={
          <List></List>}></Route> */}
        {/* </Route>
      </Routes> */}
      <PageStack></PageStack>
    </>
  );
}
export default Main;