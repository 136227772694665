import { ReactNode } from "react";
import './toast.scss';

type ToastTypes = 'normal' | 'error';

type Props = {
  show: boolean;
  text?: string;
  type?: ToastTypes;
  className?: string;
  children?: ReactNode;
}

const Toast: React.FC<Props> = (props) => {

  // useEffect(() => {
  //   console.log(`${props.show ? 'show' : 'hide'} toast`);
  // }, [props.show])

  return props.show ? <div
    className={`toast${
    props.type === 'error' ? 'toast-error' : ''}${ 
    props.className ? ` ${props.className}` : ''}`}>
      {props.text ? <p>{props.text}</p> : null}
      {props.children}
  </div> : null;
}
export default Toast;