import { ICountQuery, IWhereQuery } from 'jsstore';
import { Author, Category, DataType, General, Item, Media, Tag, tblName, tblNameType, Term } from '../libraries/db-types';
import { genPostExcerpt, words2searchReg } from '../libraries/utilities';
import { BaseService } from './storage-base-service';

/**
 * Indexeddb との接続サービス
 */

let StorageServiceInstance: StorageService;

class StorageService extends BaseService {
  /**
   * 使い方
   * BaseService の connection()を使ってトランザクションが開始できる
   * https://jsstore.net/tutorial/database/
   */
  constructor() {
    super();
    console.log('Storage Service constraction.')
    if (StorageServiceInstance) {
      throw new Error('Storage Service can be only created one instance.');
    }
    StorageServiceInstance = this;
  };

  /**
   * 基本情報
   */

  /**
   * 全件取得
   * @returns 全データ
   */
  getGeneral() {
    return this.connection.select<General>({
      from: tblName.General
    });
  }


  /**
   * 著者情報
   */

  /**
   * 全件取得
   * @returns 全著作者データ
   */
  getAuthors() {
    return this.connection.select<Author>({
      from: tblName.Authors
    })
  }

  /**
   * 1件取得
   * @returns データ
   */
   getAuthor(author_id: number) {
    return this.connection.select<Author>({
      from: tblName.Authors,
      where: {
        author_id: author_id
      }
    })
  }



  /**
   * カテゴリ情報
   */

  /**
   * 全件取得
   * @returns 全カテゴリデータ
   */
  getCategories() {
    return this.connection.select<Category>({
      from: tblName.Categories
    })
  }

  /**
   * 1件取得
   * @returns データ
   */
   getCategory(term_id: number) {
    return this.connection.select<Category>({
      from: tblName.Categories,
      where: {
        term_id: term_id
      }
    })
  }




  /**
   * タグ情報
   */

  /**
   * 全件取得
   * @returns 全タグデータ
   */
  getTags() {
    return this.connection.select<Tag>({
      from: tblName.Tags
    })
  }

  /**
   * 1件取得
   * @returns データ
   */
   getTag(term_id: number) {
    return this.connection.select<Tag>({
      from: tblName.Tags,
      where: {
        term_id: term_id
      }
    })
  }


  /**
   * タクソノミー情報
   */

  /**
   * 全件取得
   * @returns 全タクソノミーデータ
   */
  getTerms() {
    return this.connection.select<Term>({
      from: tblName.Terms
    })
  }

  /**
   * 1件以上あるデータを全件取得
   * @returns 全タクソノミーデータ
   */
 getExistTerms() {
  return this.connection.select<Term>({
    from: tblName.Terms,
    where: {
      term_count: {
        '>': 0
      }
    }
  })
}

  /**
   * 1件取得
   * @returns データ
   */
   getTerm(term_id: number) {
    return this.connection.select<Term>({
      from: tblName.Terms,
      where: {
        term_id: term_id
      }
    }).then(ret => ret[0])
  }

  /**
   * タクソノミ名から1件取得
   * @returns データ
   */
   getTermByName(term_name: string) {
    return this.connection.select<Term>({
      from: tblName.Terms,
      where: {
        term_name: {
          regex: new RegExp(`^${term_name}$`)
        }
      }
    }).then(ret => ret[0]);
  }


  /**
   * 記事情報
   */

  /**
   * 全件取得
   * @returns 全記事データ
   */
  getItems() {
    return this.connection.select<Item>({
      from: tblName.Items,
      order: {
        by: 'post_date',
        type: 'desc'
      },
      where: {
        status: 'publish',
        content: {
          "!=": ""
        }
      }
    });
  }

  /**
   * 1件取得
   * @returns データ
   */
   getItem(post_id: number) {
    return this.connection.select<Item>({
      from: tblName.Items,
      where: {
        post_id: post_id
      }
    }).then(items => {
      return items[0];
    });
  }


  /**
   * 記事情報(postのみ)
   */
  /**
   * 全件取得
   * @returns 全記事データ
   */
   getPostItems(option?: {
     order?: {by: string, type: 'asc' | 'desc'},
     where?: IWhereQuery
   }) {
    return this.connection.select<Item>({
      from: tblName.Items,
      order: option?.order || {
        by: 'post_date',
        type: 'desc'
        },
      where: option?.where || {
        post_type: 'post',
        status: 'publish'
      }
    })
  }

  /**
   * お気に入りを全件取得
   * @returns 全記事データ
   */
   getFavPostItems(option?: {
    order?: {by: string, type: 'asc' | 'desc'},
    where?: IWhereQuery
  }) {
   return this.connection.select<Item>({
     from: tblName.Items,
     order: option?.order || {
       by: 'post_date',
       type: 'desc'
       },
     where: option?.where || {
       status: 'publish',
       post_fav: true,
     }
   })
 }


  /**
   * 検索条件に一致した post_id のリストを返す
   * @param option 検索条件
   * @returns post_idのリスト
   */
  getPostIdList(option?: {
    order?: {by: string, type: 'asc' | 'desc'},
    where?: IWhereQuery
  }) {
    return this.connection.select<Item>({
      from: tblName.Items,
      order: option?.order || {
        by: 'post_date',
        type: 'desc'
        },
      where: option?.where || {
        post_type: 'post',
        status: 'publish'
      }
    }).then(items => {
      return items.map(item => {
        return item.post_id;
      })
    })
  }


  /**
   * post_id から抜粋データを作成
   * @param post_id 
   * @returns 抜粋データ
   */
  getPostExerpt(post_id: number) {
    return this.getItem(post_id).then(item => {
      return genPostExcerpt(item)
    })
  }


  /**
   * 検索
   */

  /**
   * タクソノミ検索
   * カテゴリもタグも一緒に検索する
   * @param word 検索ワード
   */
  searchTerms(words: string) {
    return this.connection.select<Term>({
      from: tblName.Terms,
      where: {
        term_name: {
          regex: words2searchReg(words)
        }
      }
    })
  }

  /**
   * タクソノミに一致する記事を検索
   * @param terms 検索ワード
   * @param fullMatch 完全一致検索する場合 true デフォルトは false
   * @returns 
   */
   searchItemsByTeams(terms: string, fullMatch = false) {
    const reg = words2searchReg(terms);
    // union で検索結果をマージして返す
    return this.connection.union([
      {
        // 本文から検索
        from: tblName.Items,
        where: {
          post_tag: {
            regex: reg
          }
        }
      },
      {
        // タイトルから検索
        from: tblName.Items,
        where: {
          category: {
            regex: reg
          }
        }
      }
    ])
  }


  /**
   * マージした検索結果を日付順にして返す
   * @param word 検索ワード
   * @returns 
   */
  searchItems(words: string) {
    const reg = words2searchReg(words);
    // union で検索結果をマージして返す
    return this.connection.union<Item[]>([
      {
        // 本文から検索
        from: tblName.Items,
        where: {
          status: 'publish',
          content: {
            regex: reg
          }
        }
      },
      {
        // タイトルから検索
        from: tblName.Items,
        where: {
          status: 'publish',
          title: {
            regex: reg
          },
          content: {
            '!=': '',
          }
        }
      }
    ]).then(ret => {
      return [...ret].sort((a, b) => b.post_date.getTime() - a.post_date.getTime());
    }).catch(err => {
      return [] as Item[];
    })
  }


  /**
   * タクソノミに一致する記事を検索
   * @param terms 検索ワード
   * @param fullMatch 完全一致検索する場合 true デフォルトは false
   * @returns 
   */
   getPostItemsByTeams(terms: string) {
    // union で検索結果をマージして返す
    return this.connection.union<Item[]>([
      {
        from: tblName.Items,
        where: {
          // status: 'publish',
          post_tag: terms,
        }
      },
      {
        from: tblName.Items,
        where: {
          // status: 'publish',
          category: terms,
        }
      }
    ]).then(ret => {
      return [...ret].filter(v => v.status === 'publish').sort((a, b) => b.post_date.getTime() - a.post_date.getTime());
    }).catch(err => {
      return [] as Item[];
    })
  }


  /**
   * 
   * @returns Media[]
   */
  getMedias() {
    return this.connection.select<Media>({
      from: tblName.Media
    });
  }


  /**
   * 
   * @returns Media[]
   */
   getMedia(post_id: number) {
    return this.connection.select<Media>({
      from: tblName.Media,
      where: {
        post_id: post_id
      }
    }).then(ret => ret[0]);
  }

  

  /**
   * データ件数取得
   * @param tableName 
   * @param where 
   * @returns Promise
   */
  getDataCount(tableName: tblNameType, where?: IWhereQuery | IWhereQuery[]) {
    const query: ICountQuery = {
      from: tableName
    }
    if (where) {
      query.where = where;
    }
    return this.connection.count(query);
  }


  /**
   * サイト情報を更新(1度削除して新しく登録する)
   * @param tableName テーブル名
   * @param id 更新対象のid
   * @param data データ1件
   * @returns 
   */
   updateGeneralData(data: DataType) {
    return this.deleteTable(tblName.General).then(() => {
      return this.addData(tblName.General, data);
    });
  }


  /**
   * データを1件更新
   * @param tableName テーブル名
   * @param id 更新対象のid
   * @param data データ1件
   * @returns 
   */
  updateData(tableName: tblNameType, id: number, data: DataType) {
    return this.connection.update({
      in: tableName,
      set: data,
      where: tableName === 'Items' ? {
        post_id: id
      } : tableName === 'General' ? {
        wxr_version: id
      } : tableName === 'Authors' ? {
        author_id: id
      } : {
        term_id: id
      }
    });
  }


  /**
   * データを1件挿入する
   * @param tableName テーブル名
   * @param data データ1件
   * @returns number | unknown[]
   */
  addData(tableName: tblNameType, data: DataType) {
    return this.connection.insert({
      into: tableName,
      values: [data],
      ignore: true
    })
  }


  /**
   * データ配列を挿入する。キーが存在していたら上書きする
   * @param tableName テーブル名
   * @param data データ1件
   * @returns number | unknown[]
   */
  addDataArray(tableName: tblNameType, dataArray: DataType[]) {
    return this.connection.insert({
      into: tableName,
      upsert: true,
      values: dataArray,
      ignore: true
    })
  }


  /**
   * テーブル名とIDを指定して削除する
   * @returns 削除したデータのid
   */
  deleteData(tableName: tblNameType, id: number) {
    return this.connection.remove({
      from: tableName,
      where: {
        id: id,
      }
    })
  }

  /**
   * テーブルの全レコードを削除
   * @param tableName 
   */
  deleteTable(tableName: tblNameType) {
    return this.connection.clear(tableName);
  }

  /**
   * 全てのテーブルの全レコードを削除
   * @param tableName 
   */
   deleteAllTables() {
     return Promise.all(
     Object.values(tblName).map((name) => {
      return  this.connection.clear(name);
     }));
  }


  /**
   * DB を削除
   */
  dropDB() {
    return this.connection.dropDb();
  }




  // getStudents() {
  //   return this.connection.select<Student>({
  //     from: 'Students'
  //   });
  // }

  // addStudent(student: Student) {
  //   return this.connection.insert({
  //     into: 'Students',
  //     values: [student]
  //   });
  // }

  // deleteStudent(studentId: number) {
  //   return this.connection.remove({
  //     from: 'Students',
  //     where: {
  //       id: studentId
  //     }
  //   });
  // }

  // getStudent(studentId: number) {
  //   return this.connection.select<Student>({
  //     from: 'Students',
  //     where: {
  //       id: studentId
  //     }
  //   });
  // }

  // updateStudent(studentId: number, value: any) {
  //   return this.connection.update({
  //     in: 'Students',
  //     set: value,
  //     where: {
  //       id: studentId
  //     }
  //   });
  // }
}

const DB = Object.freeze(new StorageService());
export default DB;