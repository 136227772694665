import './tag-chips.scss';


type Props = {
  tags: string[],
  onTagClick: (tagName: string) => void
}

const TagChips: React.FC<Props> = (props) => {
  return <>
    {props.tags.length > 0 ? <div className='tag-chips'>
      <i className='icon-label'></i>
      <ul className='chip-list'>
        {props.tags.map((tag, index) => {
          return <li key={index} className="chip-item" onClick={() => props.onTagClick(tag)}>{tag}</li>
        })}
      </ul>
    </div>
      :
      null}
  </>
}
export default TagChips;