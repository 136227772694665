export const tblName = {
  General: 'General',
  Authors: 'Authors',
  Categories: 'Categories',
  Tags: 'Tags',
  Terms: 'Terms',
  Items: 'Items',
  Media: 'Media'
} as const;
export type tblNameType = keyof typeof tblName;

export type DataType = General | Author | Category | Tag | Term | Item | Media;

export type Excerpt = {
  post_id: number,
  title: string,
  excerpt: string,
  category: string[],
  post_tag: string[],
  post_date: Date,
  post_fav: boolean,
}

export type General = {
  title: string,
  link: string,
  description: string,
  pubDate: Date,
  language: string,
  wxr_version: number,
  base_site_url: string,
  base_blog_url: string,
  generator: string,
  image?: {
    url: string,
    title: string,
    link: string,
    width: number,
    height: number
  }
}

export type Author = {
  author_id: number,
  author_login: string,
  author_email: string,
  author_display_name: string,
  author_first_name: string,
  author_last_name: string
}

export type Category = {
  term_id: number,
  category_nicename: string,
  category_parent: string,
  cat_name: string
}

export type Tag = {
  term_id: number,
  tag_slug: string,
  tag_name: string
}

export type Term = {
  term_id: number,
  term_taxonomy: string,
  term_slug: string,
  term_parent: string,
  term_name: string,
  // additional
  term_view: number, // クリック数
  term_count: number, // 件数
}

export type Item = {
  title: string,
  link: string,
  pubDate: Date,
  creator: string,
  guid: string,
  description: string,
  content: string,
  excerpt: string,
  post_id: number,
  post_date: Date,
  post_date_gmt: Date,
  post_modified: Date,
  post_modified_gmt: Date,
  comment_status: string,
  ping_status: string,
  post_name: string,
  status: string,
  post_parent: number,
  menu_order: number,
  post_type: string,
  post_password: string,
  is_sticky: number,
  attachment_url?: string,
  /**
   * 注意: post_type: attachment には無い
   * category domain=category/post_tagでデータが来るので分割する
   **/
  category: string[],
  post_tag: string[],
  postmeta?: {
    meta_key: string,
    meta_value: string
  }[],
  // additional
  post_fav: boolean // お気に入り
  post_view: number // 閲覧回数
}


export type Media = {
    title: string,
    link: string,
    pubDate: Date,
    creator: string,
    guid: string,
    description: string,
    // content: string,
    // excerpt: string,
    post_id: number,
    post_date: Date,
    post_date_gmt: Date,
    post_modified: Date,
    post_modified_gmt: Date,
    comment_status: string,
    ping_status: string,
    post_name: string,
    status: string,
    post_parent: number,
    menu_order: number,
    post_type: string,
    post_password: string,
    is_sticky: number,
    attachment_url: string,
    // category: { dataType: DATA_TYPE.Array, multiEntry: true },
    // post_tag: { dataType: DATA_TYPE.Array, multiEntry: true },
    postmeta?: {
      meta_key: string,
      meta_value: string
    }[],
    // additional
    post_fav: boolean,
    post_view: number,
  }
/**
 * 
 * title
link
pubDate
dc:creator
guid
description
content:encoded
excerpt:encoded
wp:post_id
wp:post_date
wp:post_date_gmt
wp:post_modified
wp:post_modified_gmt
wp:comment_status
wp:ping_status
wp:post_name
wp:status
wp:post_parent
wp:menu_order
wp:post_type
wp:post_password
wp:is_sticky
wp:attachment_url
category[]
wp:postmeta: {
  wp:meta_key
  wp:meta_value
}[]
 * 
 */

