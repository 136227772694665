import { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';
import ListView from '../../components/list-view/list-view';
import NoDataFound from '../../components/no-data-found/no-data-found';
import { Item } from '../../libraries/db-types';
import { myEnv } from '../../libraries/_env';
import DB from '../../services/storage-service';
import './home.scss';

type Props = {
  // appData: General;
  active: boolean;
  // singleViewRequest: (items: Item[], index: number) => void;
  updateSwitch: boolean;
  onTop: boolean;
}

type SlideParams = {
  index: number,
  key: number,
}

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const Home: React.FC<Props> = (props) => {

  // アイテムリスト
  const [posts, setPosts] = useState<Item[]>([]);
  const [pages, setPages] = useState<Item[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);

  useEffect(() => {
    // post_type=post, 件数は絞らず全件取得する
    // DB.getPostItems().then(ret => {
    //   setItems(ret);
    //   setLoaded(true);
    // });
    // 20220606 ギャラリー追加 + article 削除のため2次元配列に変更
    Promise.all([
    DB.getPostItems().then(ret => {
      setPosts(ret);
      return;
    }),
    DB.getPostItems({
      where: {
        post_type: 'page',
        status: 'publish'
      }
    }).then(ret => {
      // コンテンツの無いページを除外する（メニュー対策）
      const data = ret.filter(item => item.content.length);
      setPages(data);
      return;
    })]).then(() => {
      setLoaded(true);
    })
  }, [props.updateSwitch, props.active, props.onTop]);

  const handleChangeIndex = (index: number, indexLatest: number) => {
    // console.log('tab change:', index, indexLatest)
    setTab(pre => index);
  } 

  const slideRenderer = (params: SlideParams) => {
    const {index, key} = params;
    // const style = {
    //   slide: {
    //     margin: 0,
    //     padding: 0,
    //     // minHeight: '100%',
    //     WebkitOverflowScrolling: 'touch',
    //   } as CSSProperties
    // };

    return <ListView key={key} items={index === 0 ? posts : pages}><NoDataFound>{myEnv.strings.noDataFound}</NoDataFound></ListView>

  }
  return <section id='home' className={props.active ? 'active' : 'hidden'}>
      <div className="index">
        <div className={`bar ${tab === 0 ? 'tab-left' : 'tab-right'}`}></div>
      </div>
    <div className='container-fluid'>
      {loaded ? <>
      <VirtualizeSwipeableViews
        slideCount={2}
        style={{height: '100%'}}
        containerStyle={{
          height: '100%'
        }}
        index={tab}
        onChangeIndex={(index, indexLatest) => handleChangeIndex(index, indexLatest)}
        slideRenderer={slideRenderer}>

      </VirtualizeSwipeableViews>
      </>
      // <ListView items={items}><NoDataFound>{myEnv.strings.noDataFound}</NoDataFound></ListView>
      :
      <div aria-busy></div> }
    </div>
  </section>;
}
export default Home;