/**
 * 定数
 */
export const myEnv = {
  version: `0.3.0`,
  date: `2022/06/08`,
  title: {
    home: '',
    article: '記事',
    gallery: 'ギャラリー',
    search: '検索',
    bookmorks: 'タグ一覧',
    favorite: 'お気に入り'
  },
  topTagName: 'channel',
  siteDir: 'bbreport',
  imgUploadDir: {
    from: '/wp-content/uploads/',
    to: '/assets/img/uploads/',
  },
  excerptLength: 140,
  photoListColumns: 3,
  searchHistoryLength: 100,
  strings: {
    noSiteData: 'サイト情報を取得できませんでした。',
    noDataFound: '設定で DB 作成を実行してください。',
    noFavPosts: '何もお気に入りに追加されていません。',
    dbConnectionError: 'DB 接続エラー',
    settings: {
      title: 'Settings',
      menu: {
        version: 'アプリ情報',
        siteInfo: 'データ',
        imgPath: 'パス設定',
        create: 'DB 作成',
        deleteData: 'データ削除',
        dropDB: 'DB 削除',
      },
      version: {
        version: 'Version',
        doUpdate: 'キャッシュを削除してアプリを更新します。',
        updated: '更新の準備ができました。\nページを再読み込みします。',
        updateFailed: '更新に失敗しました。'
      },
      siteInfo: {
        fname: 'ファイル名',
        date: '読込日',
        General: 'サイト情報',
        Authors: '著者',
        Items: '投稿',
        Media: 'メディア',
        Categories: 'カテゴリー',
        Tags: 'タグ',
        Count: '件'
      },
      path: {
        siteDir: 'ディレクトリ',
        img: 'イメージソース'
      },
      buttons: {
        setDefault: 'デフォルトに戻す',
        update: '更新',
        readFile: '読込',
        delete: '削除',
        end: '終了'
      },
      phase: {
        0: 'データ取得中',
        1: 'データ登録中',
        2: 'データ更新中',
        3: 'DB 作成完了',
        4: 'DB 作成失敗'
      },
      formatNoMatch: 'ファイルの形式が合っていません。',
      siteNoMatch: '違うサイトのデータです。現在のデータを削除して新しいファイルに置換しますか？',
      siteDataExist: '同じサイトデータです。既存のデータが更新されます。',
      noFileSelected: 'ファイルが選択されていません。',
      deleteData: `データを削除します。\nこの操作は取り消せません。\nよろしいですか？`,
      deleteDb: `DB を削除します。\nこの操作は取り消せません。\nよろしいですか？`,
      abort: '中断しました。',
    },
  }
} as const;