import { CSSProperties } from 'react';
import { Item } from '../../libraries/db-types';
import DateFormat from '../date-format/date-format';
import Icon from '../icons/icons';
import './card.scss';

type Props = {
  index: number;
  style: CSSProperties;
  // data: Item[],
  item: Item,
  // post_id: number,
  onClickHandler: (index: number) => void;
}

// const Card: ComponentType<ListChildComponentProps<any>> = ({
const Card: any = ({
    index,
    style,
    // data,
    item,
    // post_id,
    onClickHandler
  } : Props) => {

  // const [item, setItem] = useState<Excerpt>({
  //   post_id: post_id,
  //   title: '',
  //   excerpt: '',
  //   category: [],
  //   post_tag: [],
  //   post_date: new Date(),
  //   post_fav: false
  // });
  // const [fav, setFav] = useState(item.post_fav);

  // // お気に入り
  // const favBottonClick = () => {
  //   console.log('fav')
  //   const updateItem = item;
  //   updateItem.post_fav = !fav;
  //   DB.updateData('Items', updateItem.post_id, updateItem).then(_ => {
  //     setFav(updateItem.post_fav);
  //   })
  // }

  // useEffect(() => {
  //   DB.getPostExerpt(post_id).then(ret => {
  //     setItem(ret);
  //     // console.log(post_id, ret);
  //   })
  // }, [post_id])

  return <>
  <article className={'item'} style={style} onClick={() => onClickHandler(index)}>
    <div>
      <header><h4>{item.title}</h4></header>
      <div className='excerpt'>
        <p>{item.excerpt}</p>
      </div>
      <footer>
        <span>
          <i className='icon-date'></i><small>
            <DateFormat date={item.post_date}></DateFormat>
          </small>
        </span>
        <span>
          <i className='icon-label'></i><small>
            {item.post_tag.length}
          </small>
        </span>
        <span>
          <i><Icon name='views' onClick={() => {}}></Icon></i><small>
            {item.post_view}
          </small>
        </span>
        <span>
          <small>
            <Icon name='favorite' onClick={() => {}} active={item.post_fav}></Icon>
          </small>
        </span>
        {/* <Spacer></Spacer> */}
      </footer>
    </div>
  </article>
  {/* <article className={'item'} style={style} onClick={() => onClickHandler(index)}>
    <div>
      <header><h4>{item.title}</h4></header>
      <div className='excerpt'>
        <p>{item.excerpt}</p>
      </div>
      <footer>
        <DateFormat date={item.pubDate}></DateFormat>
      </footer>
    </div>
  </article> */}
  {/* <SingleDialog item={item} toggle={toggleSingleView} show={showSingle}></SingleDialog> */}
  </>
}
export default Card;