import React, { CSSProperties, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { virtualize } from "react-swipeable-views-utils";
import { LocationState } from "../../components/page-stack";
import PhotoView from "../../components/photo-view/photo-view";
import SingleView from "../../components/single-view/single-view";
import TopAppBar from "../../components/top-app-bar/top-app-bar";
import { Item, Media } from "../../libraries/db-types";
import DB from "../../services/storage-service";
import './single.scss';


type Props = {
  // items: Item[],
  // index: number,
  // onBackButtonClick: () => void
  photo?: boolean,
  index?: number,
  data: LocationState;
  onBackButtonClick: () => void;
}

type SlideParams = {
  index: number,
  key: number,
}

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const Single: React.FC<Props> = (props) => {

  /**
   * Slider view を実装する場合ここで表示するデータを管理する
   * SingleViewに渡すのはitemsではなくitemになる
   */
  // 記事を特定するためのインデックス
  const [index, setIndex] = useState(props.index || 0);
  // const [before, setBefore] = useState(props.index || 0);
  const [doBack, setDoBack] = useState(false);

  const navigate = useNavigate();
  // location.state にデータが入っている

  const onBackButtonClick = () => {
    // ここでアニメーションする？
    // navigate(-1);
    props.onBackButtonClick();
  }

  // Swipe Back の時にスライドを止める
  const swipeBack = (state: boolean) => {
    setDoBack(state);
  }
  // const addViewCount = useCallback(() => {
  //   const updated = props.data.items[index];
  //   updated.post_view += 1;
  //   DB.updateData("Items", updated.post_id, updated)
  //     .then(id => {
  //       console.log('update: ', id);
  //     })
  // }, [index, props.data.items])

  // スライド変更時のイベント
  const handleChangeIndex = (idx: number) => {
    // console.log('index change', idx);
    // スライドが変更されたら index を更新して title を変更させる
    setIndex(pre => {
      return idx
    });
    // view 数を足す
    const updated = props.data.items[idx];
    updated.post_view += 1;
    DB.updateData("Items", updated.post_id, updated)
    // .then(id => {
    //   if (process.env.NODE_ENV === 'development') {
    //     console.log('update: ', id);
    //   } 
    // });
  }

  // スライド描画
  const slideRenderer = (params: SlideParams) => {
    const { index, key } = params;
    // console.log(params);
    const style = {
      slide: {
        margin: 0,
        padding: 0,
        // minHeight: '100%',
        WebkitOverflowScrolling: 'touch',
      } as CSSProperties
    };

    // alert(`SlideRenderer: ${doBack}`)

    return <article style={Object.assign({}, style.slide)} key={key}>
      {props.photo ?
        <PhotoView item={props.data.items[index] as Media} swipeBack={swipeBack}></PhotoView>
        : <SingleView item={props.data.items[index] as Item} swipeBack={swipeBack}></SingleView>}
    </article>
  }

  useEffect(() => {
    // データがなかったらトップに移動する
    if (props.data == null) {
      navigate('/');
    }
  }, [navigate, props.data]);

  // const onSwitch = (index: number, type: "move" | "end") => {
  //   console.log('onSwitch', index, type)
  // }

  // const onTouchStart = (ev: React.TouchEvent<HTMLDivElement>) => {
  //   console.log(ev.target);
  // }


  return <div className="single">
    {props.data ? <>
      <TopAppBar
        backIcon
        onBackButtonClick={onBackButtonClick}
        title={props.data.items[index].title}></TopAppBar>
      {/* <SingleView item={props.data.items[0]}></SingleView> */}
      <VirtualizeSwipeableViews
        // resistance
        // enableMouseEvents
        slideCount={props.data.items.length}
        containerStyle={{}}
        index={index}
        onChangeIndex={handleChangeIndex}
        slideRenderer={slideRenderer}
        // onSwitching={onSwitch}
        // ignoreNativeScroll
        disabled={doBack}
      ></VirtualizeSwipeableViews>
    </>
      : null}
  </div>
}
export default Single;