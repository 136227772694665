import React from 'react';
import { Media } from '../../libraries/db-types';
import './photo-view.scss';

type Props = {
  // items: Item[],
  // index: number,
  item: Media,
  swipeBack:(state: boolean) => void;
}

const PhotoView: React.FC<Props> = (props) => {

  // const [fav, setFav] = useState(props.item.post_fav);
  // const [showAction, setShowAction] = useState(false);

  // お気に入り
  // const favBottonClick = () => {
  //   const updateItem = props.item;
  //   updateItem.post_fav = !fav;
  //   DB.updateData('Items', updateItem.post_id, updateItem).then(_ => {
  //     setFav(updateItem.post_fav);
  //   })
  // }

  // // 画像のズーム
  // const onTouchStartHandler = (ev: React.TouchEvent<HTMLDivElement>) => {
  //   if (ev.touches.length > 1) {
  //     ev.preventDefault();

  //   }
  // }

  const onTouchHandler = (ev: React.TouchEvent<HTMLElement>) => {
    // console.log('touch', ev);
    if (ev.type === "touchstart") {
      // console.log('start', ev.touches.length);

      if (ev.touches.length === 1) {
        // 左端 10 ピクセル以内ならスライドを動かさない
        if (ev.touches[0].clientX <= 10) {
          // console.log('disabled');
          props.swipeBack(true);
        }
      }
    } else {
      props.swipeBack(false);
    }
  }

  // useEffect(() => {
  //   // alert('photo-view active')
  //   // fav だけ最新の情報に更新する
  //   DB.getMedia(props.item.post_id).then(ret => {
  //     // console.log(ret);
  //     setFav(ret.post_fav);
  //   });
  // }, [props.item.post_id]);


  return <section className='photo-view'
    onTouchStart={onTouchHandler}
    onTouchEnd={onTouchHandler}>
      <main>
        <div className="image-box"
          onClick={() => /*setShowAction(pre => !pre)*/{}}
          // onTouchStart={ev => onTouchStartHandler(ev)}
        >
        <img src={`${props.item.attachment_url}`} alt={`${props.item.title}`} />
        {/* <img src={`https://bbr.urchinic.com/${props.item.attachment_url}`} alt={`${props.item.title}`} /> */}
          {/* <img
           src={`https://placehold.jp/500x100.png`}
           alt={`${props.item.title}`}/> */}
        </div>
      </main>
      {/* <footer className={`actions${showAction ? ' show' : ''}`}>
          <span>
            <i className='icon-date'></i><small>
              <DateFormat date={props.item.post_date}></DateFormat>
            </small>
          </span>
          <Spacer></Spacer>
          <div className='actions'>
            <Icon name='favorite' onClick={favBottonClick} active={fav}></Icon>
          </div>
      </footer> */}
  </section>
}
export default PhotoView;