import Icon, { IconName } from "../icons/icons";
import './navigation-bar.scss';

type Props = {
  active: IconName;
  onActiveChange: (name: IconName) => void;
}

const NavigationBar: React.FC<Props> = (props) => {

  const onClickHandler = (name: IconName) => {
    // Active を変更する
    props.onActiveChange(name);
  }

  return <footer className="navigation-bar">
    <ul>
      <li>
        <Icon name="home" active={props.active === 'home'} onClick={onClickHandler}></Icon>
      </li>
      {/* <li>
        <Icon name="article" active={props.active === 'article'} onClick={onClickHandler}></Icon>
      </li> */}
      <li>
        <Icon name="photos" active={props.active === 'photos'} onClick={onClickHandler}></Icon>
      </li>
      <li>
        <Icon name="search" active={props.active === 'search'} onClick={onClickHandler}></Icon>
      </li>
      <li>
        <Icon name="bookmarks" active={props.active === 'bookmarks'} onClick={onClickHandler}></Icon>
      </li>
      <li>
        <Icon name="favorite" active={props.active === 'favorite'} onClick={onClickHandler}></Icon>
      </li>
    </ul>
  </footer>
}
export default NavigationBar