import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Item } from '../../libraries/db-types';
import { countFormat } from '../../libraries/utilities';
import DB from '../../services/storage-service';
import DateFormat from '../date-format/date-format';
import Icon from '../icons/icons';
import { LocationState } from '../page-stack';
import Spacer from '../spacer/spacer';
import TagChips from '../tag-chips/tag-chips';
import './single-view.scss';

type Props = {
  // items: Item[],
  // index: number,
  item: Item,
  swipeBack:(state: boolean) => void;
}

const SingleView: React.FC<Props> = (props) => {

  const navigate = useNavigate();
  const [fav, setFav] = useState(props.item.post_fav);

  const listViewRequest = (tagName: string) => {
    // console.log('tag click', tagName);
    // props.listViewRequest({post_tag: tagName});
    // View 数を一つ足す
    DB.getTermByName(tagName).then(term => {
      if (term) {
        term.term_view += 1;
        // データをアップデート
        DB.updateData('Terms', term.term_id, term);
      }
    });

    // 画面遷移
    DB.getPostItemsByTeams(tagName).then(ret => {
      // console.log('navigate to list', ret,(data as Term).term_name);
      ret.length && navigate('/list', { state: {
        title: `# ${tagName}`,
        items: ret,
        view: 'list'
      } as LocationState });
    });
  }

  // お気に入り
  const favBottonClick = () => {
    const updateItem = props.item;
    updateItem.post_fav = !fav;
    DB.updateData('Items', updateItem.post_id, updateItem).then(_ => {
      setFav(updateItem.post_fav);
    })
  }

  const onTouchHandler = (ev: React.TouchEvent<HTMLElement>) => {
    // console.log('touch', ev);
    if (ev.type === "touchstart") {
      // console.log('start', ev.touches.length);

      if (ev.touches.length === 1) {
        // 左端 15 ピクセル以内ならスライドを動かさない
        if (ev.touches[0].clientX <= 15) {
          // console.log('disabled');
          props.swipeBack(true);
        }
      }
    } else {
      props.swipeBack(false);
    }
  }

  useEffect(() => {
    // 最新の情報に更新する
    DB.getItem(props.item.post_id).then(ret => {
      // console.log(ret);
      setFav(ret.post_fav);
    });
  }, [props.item.post_id]);


  return <section className='single-view'
    onTouchStart={onTouchHandler}
    onTouchEnd={onTouchHandler}
  >
    {props.item.content.length ? <>
      <main dangerouslySetInnerHTML={{ __html: props.item.content }}></main>
      {/* <hr /> */}
      <footer>
        {props.item.post_tag.length > 0 && <hr />}
        <TagChips tags={props.item.post_tag} onTagClick={listViewRequest}></TagChips>
        <hr />
        {props.item.category.length !== 0 && <><div className='categories'>
          {props.item.category.map((category, index) => {
            return <span key={index} onClick={() => listViewRequest(category)}>
              <i className='icon-folder'></i><small>{category}</small>
            </span>
            })
          }
          </div><hr /></>}
        <div className='single-meta'>
          <span>
            <i className='icon-date'></i><small>
              <DateFormat date={props.item.post_date}></DateFormat>
            </small>
          </span>
          <span>
            <i><Icon name='views' onClick={() => {}}></Icon></i><small>
              {countFormat(props.item.post_view)}
            </small>
          </span>
          <Spacer></Spacer>
          <div className='actions'>
            <Icon name='favorite' onClick={favBottonClick} active={fav}></Icon>
          </div>
        </div>
      </footer>
    </>
      :
      <main>Loading...</main>}
  </section>
}
export default SingleView;