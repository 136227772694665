import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { IconName } from "../components/icons/icons";
import NavigationBar from "../components/navigation-bar/navigation-bar";
import TopAppBar from "../components/top-app-bar/top-app-bar";
import { General } from "../libraries/db-types";
import Favorites from "../templates/favorites/favorites";
import Gallery from "../templates/gallery/gallery";
import Home from "../templates/home/home";
import Search from "../templates/search/search";
import Terms from "../templates/terms/terms";

type Props = {
  // appEl: HTMLElement | HTMLElement[] | HTMLCollection | NodeList | undefined;
  // active: IconName;
  appData: General;
  // updateSwitch: boolean;
  onUpdateRequest: () => void;
}

const Content: React.FC<Props> = (props) => {

  // const [items, setItems] = useState<Item[]>([]);
  // const [sIndex, setSIndex] = useState<number>(0);
  // const [singleViewState, setSingleViewState] = useState(false);

  // const toggleSingleView = (isOpen: boolean) => {
  //   setSingleViewState(isOpen);
  // }

  // const showSingleView = (items: Item[], index: number) => {
  //   setItems(items);
  //   setSIndex(index);
  //   setSingleViewState(true)
  // }
  const [activeMenu, setActiveMenu] = useState<IconName>('home');
  // 各ページにデータ更新の必要性を伝えるための変数。値に意味はなく変更検知で使う。
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [onTop, setOnTop] = useState(true);
  const appBarRef = useRef();
  const searchRef = useRef();
  const termsRef = useRef();
  const location = useLocation();

  const onActiveChange = (name: IconName) => {
    // console.log('click');
    if (activeMenu === 'search' && name === 'search') {
      appBarRef.current && (appBarRef.current as any).focusRequest();
    }
    setActiveMenu(name);
  }

  // 設定終了時のイベント
  const onUpdateRequest = () => {
    // Homeに戻る
    setActiveMenu('home');

    // データ更新をリクエスト
    setUpdateSwitch(pre => !pre);

    // サイト情報をアップデート
    props.onUpdateRequest();
  }

  const onSearchRequest = (words: string) => {
    // console.log('onSearchRequest')
    searchRef.current && (searchRef.current as any).onSearchRequest(words);
  }

  const setSearchWords = (words: string) => {
    // console.log('setSearchWords')
    appBarRef.current && (appBarRef.current as any).setSearchWords(words);
  }

  const onFilterChange = (tagFilter: string) => {
    termsRef.current && (termsRef.current as any).setFilterWords(tagFilter);
  }

  const onSelectChange = (sortType: string) => {
    termsRef.current && (termsRef.current as any).setSortType(sortType);
  }


  useEffect(() => {
    if (location.pathname === '/') {
      // console.log(location)
      // setUpdateSwitch(pre => !pre)
      setOnTop(pre => !pre);
    }
  }, [location])


  return <div className="contents">
    <TopAppBar
      ref={appBarRef}
      title={`${props.appData.title}<small>${props.appData.description}</small>`}
      active={activeMenu}
      // onBackButtonClick={() => onBackButtonClick()}
      onSearchRequest={onSearchRequest}
      settingIcon={activeMenu === 'home' || activeMenu === 'article'}
      onSettingEnd={onUpdateRequest}
      onFilterChange={onFilterChange}
      onSelectChange={onSelectChange}></TopAppBar>
    <main>
      <Home
        active={activeMenu === 'home'}
        // appData={props.appData}
        // singleViewRequest={showSingleView}
        onTop={onTop}
        updateSwitch={updateSwitch}></Home>
      {/* <Articles active={activeMenu === 'article'} updateSwitch={updateSwitch}></Articles> */}
      
      <Gallery
        active={activeMenu === 'photos'}
        updateSwitch={updateSwitch}></Gallery>
      
      <Search ref={searchRef}
        active={activeMenu === 'search'}
        setSearchWords={setSearchWords} /*updateSwitch={updateSwitch}*/></Search>
      
      <Terms ref={termsRef}
        active={activeMenu === 'bookmarks'}
        updateSwitch={updateSwitch}></Terms>
      
      <Favorites
        active={activeMenu === 'favorite'}
        onTop={onTop}
        updateSwitch={updateSwitch}></Favorites>
      {/* <SingleDialog appEl={props.appEl} items={items} index={sIndex} toggle={toggleSingleView} show={singleViewState}></SingleDialog> */}
    </main>
    <NavigationBar active={activeMenu} onActiveChange={onActiveChange}></NavigationBar>

  </div>
}
export default Content;